
import { Component, Vue } from 'vue-property-decorator';
import OrderCartSection from '@/components/Order/OrderCartSection.vue';
import { dispatchGetDeliveryOptionsList, dispatchGetPaymentMethodsList, dispatchGetRecentInvoiceDetails, dispatchProcessInvoice, dispatchSaveInvoiceDetails } from '@/store/products/actions';
import { commitSetInvoiceDetail, commitSetProductsInvoice } from '@/store/products/mutations';
import { readInvoiceDetail, readProductsInvoice } from '@/store/products/getters';
import { getLocalSessionId } from '@/utils';
import { readUserProfile } from '@/store/main/getters';
import { dispatchGetMyDeliveryAddressesList, dispatchGetNovaPoshtaCities, dispatchGetNovaPoshtaWarehouses, dispatchSaveMyDeliveryAddress } from '@/store/main/actions';
import { INovaPoshtaCity, INovaPoshtaWarehouse, IUserDeliveryAddress } from '@/interfaces';
import { IDeliveryOption, IPaymentMethod } from '@/interfaces/products';
import { latLng } from "leaflet";
import { mask } from 'vue-the-mask';

@Component({
  directives: {mask},
  components: {
    OrderCartSection,
  },
})
export default class Checkout extends Vue {
  public step: number = 1;
  public citiesChoices: INovaPoshtaCity[] = [];
  public warehousesChoices: INovaPoshtaWarehouse[] = [];
  public deliveryOptionsList: IDeliveryOption[] = [];
  public deliveryAddressesList: IUserDeliveryAddress[] = [];
  public paymentMethodsList: IPaymentMethod[] = [];
  public deliveryOption: number | null = null;
  public paymentMethod: number | null = null;
  public deliveryDetails: any = {
    delivery_option: null,
    user_delivery_address: null,
    street: '',
    house: '',
    appartment: '',
    nova_poshta_number: '',
  };
  public userDetails: any = {
    first_name: '',
    last_name: '',
    phone: '',
    phone_clean: '',
    city_code_np: '',
    city: '',
    email: '',
  };
  public loadingState: any = {
    saveAddress: false,
    page: true,
  };
  public userDetailsError: boolean = false;
  public citySearch: string = '';
  public showMap: boolean = false;
  public locationMarker: any = null;

  public async mounted() {
    if (!this.productsInvoice) {
      const response = await dispatchGetRecentInvoiceDetails(this.$store, {anon_session_id: getLocalSessionId(),});
      if (response) {
        commitSetProductsInvoice(this.$store, response);
      }
    }
    if (this.userProfile && this.invoiceDetails) {
      this.userDetails = {
        first_name: this.invoiceDetails.first_name ? this.invoiceDetails.first_name : this.userProfile.first_name,
        last_name: this.invoiceDetails.last_name ? this.invoiceDetails.last_name : this.userProfile.last_name,
        phone_clean: this.invoiceDetails.phone ? this.invoiceDetails.phone : this.userProfile.phone,
        phone: this.invoiceDetails.phone ? this.invoiceDetails.phone : this.userProfile.phone,
        city_code_np: this.invoiceDetails.city_code_np ? this.invoiceDetails.city_code_np : this.userProfile.city_code_np,
        city: this.invoiceDetails.city ? this.invoiceDetails.city : this.userProfile.city,
        email: this.invoiceDetails.email ? this.invoiceDetails.email : this.userProfile.email,
      };
      if (this.userDetails.city) {
        this.handleSearchByKeyword(this.userDetails.city, null);
      }
    }
    if (!this.invoiceDetails || this.invoiceDetails.items.length === 0) {
      this.$router.push('/home');
    }
    this.prepareStep(1);
    this.loadingState.page = false;
  }

  public async prepareStep(step) {
    setTimeout(()=>{window.scrollTo(0, 0);}, 100);
    this.step = step;
    if (this.step === 1) {
      // this.citiesChoices = await dispatchGetNovaPoshtaCities(this.$store, {search: this.citySearch});
      } else if (this.step === 2) {
      this.deliveryOptionsList = await dispatchGetDeliveryOptionsList(this.$store);
      if (this.userDetails.city_code_np) {
        this.warehousesChoices = await dispatchGetNovaPoshtaWarehouses(this.$store, {cityRef: this.userDetails.city_code_np});
      }
      if (this.userProfile) {
        this.deliveryAddressesList = await dispatchGetMyDeliveryAddressesList(this.$store, {});
      } else {
        this.deliveryDetails.user_delivery_address = 'new';
      }
    } else if (this.step === 3) {
      this.paymentMethodsList = await dispatchGetPaymentMethodsList(this.$store);
    }
  }

  public async handleChangeDeliveryAddress(event) {
    let option: any = null;
    for (let i in this.deliveryAddressesSelectList) {
      if (this.deliveryAddressesSelectList[i].id === event) {
        option = this.deliveryAddressesSelectList[i];
      }
    }
    if (!option || option.id === 'new') {
      this.deliveryDetails.street = '';
      this.deliveryDetails.house = '';
      this.deliveryDetails.appartment = '';
    }
  }

  public async handleSaveAddress() {
    if (this.deliveryDetails.user_delivery_address === 'new' && this.deliveryDetails.street && this.deliveryDetails.house) {
      this.loadingState.saveAddress = true;
      const formData = new FormData();
      formData.append("city_code_np", this.invoiceDetails ? this.invoiceDetails.city_code_np : '');
      formData.append("city", this.invoiceDetails ? this.invoiceDetails.city : '');
      formData.append("street", this.deliveryDetails.street);
      formData.append("house_number", this.deliveryDetails.house);
      formData.append("apartment_number", this.deliveryDetails.appartment);
      const response = await dispatchSaveMyDeliveryAddress(this.$store, formData);
      if (response) {
        this.deliveryAddressesList = await dispatchGetMyDeliveryAddressesList(this.$store, {});
        this.deliveryDetails.user_delivery_address = response.id;
      }
      this.loadingState.saveAddress = false;
    }
  }

  public async handleChangeCity(event) {
    this.userDetails.city = '';
    for (let i in this.citiesChoices) {
      if (this.citiesChoices[i].DeliveryCity === event) {
        this.userDetails.city = this.citiesChoices[i].Present;
        break;
      }
    }
    if (this.userDetails.city_code_np) {
      this.warehousesChoices = await dispatchGetNovaPoshtaWarehouses(this.$store, {cityRef: this.userDetails.city_code_np});
    }
  }

  public async handleSearchByKeyword(search, loading) {
    this.citySearch = search;
    if (this.citySearch !== '') {
      this.citiesChoices = await dispatchGetNovaPoshtaCities(this.$store, {search: this.citySearch});
    }
  }

  public openLocationsMap() {
    if (this.warehouseInfo) {
      this.locationMarker = latLng(Number(this.warehouseInfo.Latitude), Number(this.warehouseInfo.Longitude));
      this.showMap = true;
    }
  }

  public validateUserDetails() {
    this.userDetailsError = false;
    if (!this.userDetails.email || (this.userDetails.email && !/(.+)@(.+){1,}\.(.+){2,}/.test(this.userDetails.email))) {
      this.userDetailsError = true;
    } else if (!this.userDetails.phone || !this.userDetails.first_name || this.userDetails.phone.length < 13) {
      this.userDetailsError = true;
    }
    return !this.userDetailsError;
  }

  public async validatePhone(payload) {
    this.userDetails.phone = payload.formattedNumber ? payload.formattedNumber : '';
  }
  
  public async handleSaveUserDetails() {
    if (!this.invoiceDetails || this.invoiceDetails.items.length === 0) {
      this.$toast.error(this.$t('Корзина пустая').toString());
      return;
    }
    if (!await this.validateUserDetails()) {
      this.$toast.error(this.$t('Заполните свои контактные данные').toString());
      return;
    }
    const result = await dispatchSaveInvoiceDetails(
      this.$store, {...this.userDetails, ...{id: this.invoiceDetails.id}});
    if (result === true) {
      // this.$toast.error(this.$t('Invoice sent to approval').toString());
      this.prepareStep(2);
    } else {
      if (result.email) {
        this.$toast.error(result.email[0]);
      } else {
        this.$toast.error(this.$t('Error').toString());
      }
    }
  }

  public handleChangeDeliveryOption(option) {
    this.deliveryOption = option;
  }

  public handleChangePaymentMethod(method) {
    this.paymentMethod = method;
  }

  public async validateAndGenerateDeliveryDetails() {
    if (!this.deliveryOption) {
      this.$toast.error(this.$t('Выберите способ доставки').toString());
      return null;
    }
    let deliveryOption: any = null;
    for (let i in this.deliveryOptionsList) {
      if (this.deliveryOptionsList[i].id === this.deliveryOption) {
        deliveryOption = this.deliveryOptionsList[i];
      }
    }
    if (deliveryOption.option_type === 'np') {
      if (!this.userDetails.city_code_np) {
        this.$toast.error(this.$t('Выберите город доставки').toString());
        return null;
      }
      if (!this.deliveryDetails.nova_poshta_number) {
        this.$toast.error(this.$t('Выберите отделение новой почты').toString());
        return null;
      }
      return {
        // @ts-ignore
        id: this.invoiceDetails.id,
        delivery_option: this.deliveryOption,
        city_code_np: this.userDetails.city_code_np,
        city: this.userDetails.city,
        nova_poshta_number: this.deliveryDetails.nova_poshta_number,
        user_delivery_address: this.deliveryDetails.user_delivery_address && this.deliveryDetails.user_delivery_address !== 'new' ? this.deliveryDetails.user_delivery_address : null,
      };
    } else if (deliveryOption.option_type !== 'pic') {
      if (this.userProfile) {
        if (!this.deliveryDetails.user_delivery_address || this.deliveryDetails.user_delivery_address === 'new') {
          this.$toast.error(this.$t('Выберите адрес доставки').toString());
          return null;
        }
        return {
          // @ts-ignore
          id: this.invoiceDetails.id,
          delivery_option: this.deliveryOption,
          user_delivery_address: this.deliveryDetails.user_delivery_address && this.deliveryDetails.user_delivery_address !== 'new' ? this.deliveryDetails.user_delivery_address : null,
        };
      } else {
        if (!this.deliveryDetails.street || !this.deliveryDetails.house) {
          this.$toast.error(this.$t('Укажите адрес доставки').toString());
          return null;
        }

        return {
          // @ts-ignore
          id: this.invoiceDetails.id,
          delivery_option: this.deliveryOption,
          delivery_address: `${this.deliveryDetails.street}, ${this.deliveryDetails.house} ${this.deliveryDetails.appartment}`,
        };
      }
    } else {
      return {
        // @ts-ignore
        id: this.invoiceDetails.id,
        delivery_option: this.deliveryOption,
      };
    }
  }

  public async handleSaveDeliveryDetails() {
    if (!this.invoiceDetails || this.invoiceDetails.items.length === 0) {
      return;
    }
    const payload = await this.validateAndGenerateDeliveryDetails();
    if (!payload) {
      return;
    }
    const result = await dispatchSaveInvoiceDetails(
      this.$store, payload);
    if (result) {
      // this.$toast.error(this.$t('Invoice sent to approval').toString());
      this.prepareStep(3);
    } else {
      this.$toast.error(this.$t('Error').toString());
    }
  }

  public async handleCheckout() {
    if (!this.invoiceDetails || this.invoiceDetails.items.length === 0) {
      return;
    }
    if (!this.paymentMethod) {
      this.$toast.error(this.$t('Выберите способ оплаты').toString());
      return;
    }
    const response = await dispatchProcessInvoice(this.$store, {
      // @ts-ignore
      invoice_id: this.invoiceDetails.id,
      payment_method: this.paymentMethod,
    });
    if (response) {
      if (response.checkout_url) {
        window.location.href = response.checkout_url;
      } else {
        this.$toast.success(this.$t('Заказ отправлен в обработку').toString());
        commitSetProductsInvoice(this.$store, null);
        commitSetInvoiceDetail(this.$store, null);
        // this.step = 4;
        this.$router.push('/page/thank_you');
      }
    } else {
      this.$toast.error(this.$t('Что-то пошло не так').toString());
    }
  }

  get invoiceDetails() {
    return readInvoiceDetail(this.$store);
  }

  get productsInvoice() {
    return readProductsInvoice(this.$store);
  }

  get userProfile() {
    return readUserProfile(this.$store);
  }

  get citiesList() {
    let citiesList: any = [];
    for (let i in this.citiesChoices) {
      citiesList.push({
        id: this.citiesChoices[i].DeliveryCity.toString(),
        label: this.citiesChoices[i].Present,
      });
    }
    return citiesList;
  }

  get warehousesList() {
    let warehousesList: any = [];
    for (let i in this.warehousesChoices) {
      warehousesList.push({
        id: this.warehousesChoices[i].Ref.toString(),
        label: this.warehousesChoices[i].Description,
      });
    }
    return warehousesList;
  }

  get warehouseInfo() {
    for (let i in this.warehousesChoices) {
      if (this.deliveryDetails.nova_poshta_number === this.warehousesChoices[i].Ref) {
        return this.warehousesChoices[i];
      }
    }
    return null;
  }

  get mapInfo() {
    let center: any = [50.4547, 30.5238];
    if (this.locationMarker) {
      center = [this.locationMarker.lat, this.locationMarker.lng];
    }
    return {
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
          '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 15,
      center,
      // iconSize: 64,
    };
  }

  get deliveryAddressesSelectList() {
    let deliveryAddressesSelectList: any = [];
    if (this.userProfile && this.userProfile.user_type === 'client') {
      deliveryAddressesSelectList.push({
        id: 'new',
        label: this.$t('Добавить адресс'),
      });
    }
    for (let i in this.deliveryAddressesList) {
      deliveryAddressesSelectList.push({
        id: this.deliveryAddressesList[i].id,
        label: this.deliveryAddressesList[i].address_str,
      });
    }
    return deliveryAddressesSelectList;
  }
  
}
