
import { dispatchGetInvoiceDetails, dispatchRemoveFromCart, dispatchToggleProductInCart } from '@/store/products/actions';
import { readInvoiceDetail, readProductsInvoice } from '@/store/products/getters';
import { getLocalSessionId } from '@/utils';
import { Component, Vue } from 'vue-property-decorator';

@Component({
    components: {
    },
})

export default class OrderCartSection extends Vue {

  public async handleInputCountNumber(id) {
    let inCart: any = null;
    if (this.invoiceDetails) {
      for (let i in this.invoiceDetails.items) {
        // @ts-ignore
        if (this.invoiceDetails.items[i].object_id === id) {
          if (this.invoiceDetails.items[i].quantity && isNaN(this.invoiceDetails.items[i].quantity)) {
            this.invoiceDetails.items[i].quantity = 1
          } else if (this.invoiceDetails.items[i].quantity && Number(this.invoiceDetails.items[i].quantity) <= 0) {
            this.invoiceDetails.items[i].quantity = 1
          }
          inCart = {
            id: this.invoiceDetails.items[i].id,
            quantity: this.invoiceDetails.items[i].quantity,
          };
        }
      }
    }
    if (inCart) {
      if (inCart.quantity && Number(inCart.quantity) && Number(inCart.quantity) > 0) {
        await dispatchToggleProductInCart(this.$store, {invoice_item: inCart.id, action: 'count', quantity: Number(inCart.quantity)});
      }
    }
  }

  public async handleAddToCart(id) {
    let inCart: any = null;
    if (this.invoiceDetails) {
      for (let i in this.invoiceDetails.items) {
        if (this.invoiceDetails.items[i].object_id === id) {
          inCart = {
            id: this.invoiceDetails.items[i].id,
            quantity: this.invoiceDetails.items[i].quantity,
          };
        }
      }
    }
    if (inCart) {
      await dispatchToggleProductInCart(this.$store, {invoice_item: inCart.id, action: 'add'});
    }
  }

  public async handleRemoveFromCart(id) {
    if (this.invoiceDetails) {
      for (let i in this.invoiceDetails.items) {
        if (this.invoiceDetails.items[i]) {
          if (this.invoiceDetails.items[i].object_id === id && this.invoiceDetails.items[i].quantity > 1) {
            await dispatchToggleProductInCart(
              this.$store, {invoice_item: this.invoiceDetails.items[i].id, action: 'remove'},
            );
          }
        }
      }
      if (!this.invoiceDetails || this.invoiceDetails.items.length === 0) {
        this.$router.push('/home');
      }
    }
  }

  public async handleDeleteFromCart(id) {
    setTimeout(async () => {
      await dispatchRemoveFromCart(this.$store, {id, anon_session_id: getLocalSessionId()});
      if (this.productsInvoice) {
        await dispatchGetInvoiceDetails(this.$store, {invoice_id: this.productsInvoice, anon_session_id: getLocalSessionId()});
      }
      if (!this.invoiceDetails || this.invoiceDetails.items.length === 0) {
        this.$router.push('/home');
      }
    }, 500);
  }

  get productsInvoice() {
    return readProductsInvoice(this.$store);
  }
  get invoiceDetails() {
    return readInvoiceDetail(this.$store);
  }
}
